








































































































































import Vue from 'vue';
import Int64LookupResourceViewModel from '@/src/services/viewModel/resource/Int64LookupResourceViewModel';
import SafetyAnchorPointResourceViewModel from '@/src/services/viewModel/resource/SafetyAnchorPointResourceViewModel';
import SafetyHookResourceViewModel from '@/src/services/viewModel/resource/SafetyHookResourceViewModel';

export default Vue.extend({
  auth: true,
  data: () => ({
    imagesLoading: false,
    safetyAnchorPointImageFiles: {} as Record<string, File[]>,
    // selectedSafetyHook: undefined as SafetyHookResourceViewModel | undefined,
    selectedSafetyHook: new SafetyHookResourceViewModel(),
    model: new SafetyAnchorPointResourceViewModel(),
    safetyHooksSelect: [] as Int64LookupResourceViewModel[],
    onSiteDocumentationSectionExpanded: false,
  }),
  computed: {
    isCreate(): boolean {
      return !this.safetyAnchorPointId;
    },
    isUpdate(): boolean {
      return !this.isCreate;
    },
    safetyAnchorPointId() {
      const id = this.$router.currentRoute?.params?.id as string | undefined;
      if (id) {
        return Number.parseInt(id, 10);
      }
      return undefined;
    },
    siHaDocumentationId() {
      const id = this.$router.currentRoute?.params?.siHaDocumentationId as string | undefined;
      if (id) {
        return Number.parseInt(id, 10);
      }
      return undefined;
    },
  },
  watch: {
    'model.safetyHookId': {
      handler() {
        this.populateSafetyHook();
      },
    },
    'model.isInstalledOnCounterBatten': {
      handler() {
        this.populateSafetyHook();
      },
    },
  },
  async mounted() {
    await this.populateData();
  },
  methods: {
    async downloadImages() {
      this.imagesLoading = true;
      this.safetyAnchorPointImageFiles = await this.$service.api.siHaSafetyAnchorPoint.downloadImageArrays(this.model);
      this.imagesLoading = false;
    },
    async onAnchorPointImageInput(index: number | string, files: File[]) {
      const [id] = await this.$service.api.documents.uploadMultipleDocuments(files);
      (this.model as any)[`documentationImage${index}Id`] = id;
    },
    async populateSafetyHook() {
      const id = this.model.safetyHookId;
      this.selectedSafetyHook = await this.$service.api.siHaSafetyHook.getSafetyHookById(id!);
      if (this.selectedSafetyHook.canFastenOnCounterbatten === false && this.model.isInstalledOnCounterBatten) {
        this.model.isInstalledOnCounterBatten = false;
      }
      this.presetScrewLength();
    },
    presetScrewLength() {
      this.model.screwLength = this.selectedSafetyHook!.standardScrewLength;
      if (this.model.isInstalledOnCounterBatten) {
        this.model.screwLength = this.selectedSafetyHook!.counterbattenScrewLength;
      }
    },
    async populateData() {
      let loadedModel: SafetyAnchorPointResourceViewModel;
      if (this.isCreate) {
        loadedModel = new SafetyAnchorPointResourceViewModel();
        loadedModel.siHaDocumentationId = this.siHaDocumentationId;
      } else {
        loadedModel = await this.$service.api.siHaSafetyAnchorPoint.getSafetyAnchorPoint(this.safetyAnchorPointId!);
      }
      loadedModel.siHaDocumentationId = this.siHaDocumentationId;

      this.$withoutWatchers(() => {
        this.model = loadedModel;
      });

      await this.downloadImages();
    },
    // Events on Buttons
    async btnSaveAndCloseClick() {
      const saveDataResult = await this.saveData();
      if (saveDataResult) {
        this.navigateBack();
      }
    },
    async btnSaveClick() {
      await this.saveData();
    },
    async btnSaveAndNextClick() {
      this.saveData();
      this.safetyAnchorPointId = undefined; // clear data
      this.populateData();
      this.navigateNext(undefined);
    },
    async btnBackClick() {
      this.navigateBack();
    },
    // Building blocks for Events
    async saveData() {
      let returnId;
      if (!this.model.validate()) {
        return returnId;
      }
      if (this.isUpdate) {
        returnId = await this.$service.api.siHaSafetyAnchorPoint.updateSafetyAnchorPoint(this.model);
      } else {
        returnId = await this.$service.api.siHaSafetyAnchorPoint.createSafetyAnchorPoint(this.model);
      }
      return returnId;
    },
    async navigateBack() {
      this.$router.push(`/glaromat/il/siha/process/updateDocumentation/${this.siHaDocumentationId}?step=3`);
    },
    async navigateNext(id: number|undefined) {
      this.$router.replace(`/glaromat/il/siha/documentation/${this.siHaDocumentationId}/safetyAnchorPoint/${id}`);
    },
  },
});
